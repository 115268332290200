/**
 * Created by ZengFanhui on 2021/1/7 14:18
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取养户列表
 * @param type -1:全部
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetFarmerList (account = '', pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    account,
    pageIndex,
    pageSize
  }
  return doHttp('/appFarmer/list.action', HTTP_TYPE.GET, params)
}

/**
 * 编辑养户
 * @param params
 * @returns {*}
 */
export function apiEditFarmer(params) {
  return doHttp('/appFarmer/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 删除养户
 * @param account
 * @returns {*}
 */
export function apiDeleteFarmer(account) {
  let params = {account}
  return doHttp('/appFarmer/delete.action', HTTP_TYPE.GET, params)
}

/**
 * 解绑
 * @param userIds
 * @return {*}
 */
export function apiUnBindFarmer (userAccounts) {
  let params = {userAccounts}

  return doHttp('/appFarmer/unBindFarmer.action', HTTP_TYPE.GET, params)
}
