<template>
  <div>
    <mt-button class="mb15">
      <el-input class="input" v-model="account" placeholder="账号 / 姓名 / ID" clearable @keyup.enter.native="search"
                @clear="search"></el-input>
      <el-button @click="onAddBtnClick" type="primary">新增</el-button>
      <el-button @click="onUnBindBtnClick()" type="primary">微信解绑</el-button>
      <el-button @click="onRefreshBtnClick">刷新</el-button>
    </mt-button>
    <el-table
      ref="farmerTable"
      :data="farmerList"
      stripe
      :height="tableHeight"
      :maxHeight="tableHeight"
      @row-click="farmerRowSelect"
      @selection-change="farmerRowChange"
      row-class-name="table-row-cursor"
      style="width: 100%;">
      <el-table-column
        header-align="center"
        align="center"
        type="selection"
        width="30">
      </el-table-column>
      <el-table-column
        header-align="left"
        type="index"
        label="#"
        :index="formatterIndex"
        width="50">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="姓名">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="phone"
        label="账号">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="easDBKey"
        label="账套">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="easFarmerId"
        width="300"
        label="EASID">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.wechatOpenId" type="success">已绑定</el-tag>
          <el-tag v-else type="info">未绑定</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="top">
            <el-button @click.stop="onEditBtnClick(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="farmerTotal"
      class="pagination">
    </el-pagination>

    <el-dialog :title="!model.easFarmerId ? '新增养户' : '编辑养户'" :visible.sync="dialogVisible" width="50%">
      <el-form :rules="rules" ref="dataForm" :model="model" label-position="left" label-width="100px" class="data-form"
               style='width: 500px; margin:0 auto;'>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="model.name"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="phone">
          <el-input :disabled="operation === 'edit'" v-model="model.phone"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input :disabled="!dialogUserPwdDisabled" v-model="model.password"></el-input>
          <el-checkbox-group v-model="dialogUserPwdDisabled">
            <el-checkbox label="修改（默认密码为:123456）"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="EAS养户ID" prop="easFarmerId">
          <el-input v-model="model.easFarmerId"></el-input>
        </el-form-item>
        <el-form-item label="账套" prop="easDBKey">
          <el-select
            v-model="model.easDBKey"
            collapse-tags
            placeholder="请选择账套">
            <el-option
              v-for="item in easDBKey"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editFarmer">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import {apiGetFarmerList, apiEditFarmer, apiUnBindFarmer, apiDeleteFarmer} from '../../api/qyFarmer'
  import {tableMixin} from '../../mixins/table'

  export default {
    name: 'QyFarmer',
    mixins: [tableMixin],
    components: {MtButton},
    data() {
      return {
        farmerList: [],
        farmerTotal: 0,
        selectFarmerList: [],
        account: '',
        dialogVisible: false,
        dialogUserPwdDisabled: false,
        easDBKey: [{
          id: 'anseas',
          name: 'anseas'
        }, {
          id: 'anxin',
          name: 'anxin'
        }],
        model: {
          easDBKey: 'anseas',
          name: '',
          phone: '',
          password: '',
          easFarmerId: ''
        },
        rules: {
          name: [{required: true, message: '不能为空', trigger: 'blur'}],
          easDBKey: [{required: true, message: '不能为空', trigger: 'blur'}],
          phone: [{required: true, message: '不能为空', trigger: 'blur'}],
          easFarmerId: [{required: true, message: '不能为空', trigger: 'blur'}]
        },
        operation: 'add'
      }
    },
    created() {
    },
    activated() {
      this._getFarmerList()
    },
    methods: {
      onAddBtnClick() {
        this.operation = 'add'
        this.dialogVisible = true
        this._clearModel()
        this._resetModel()
      },
      onEditBtnClick(row) {
        this.operation = 'edit'
        this._clearModel()
        this.dialogVisible = true
        this.model = Object.assign({}, this.model, row)
        this.model.password = ''
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除该养户，删除后养户将无法正常登录？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteFarmer(row.phone).then(data => {
            this._getFarmerList()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      editFarmer() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            apiEditFarmer(this.model).then(({data}) => {
              this.pageIndex = 1
              this._getFarmerList()
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: this.model.id === 0 ? '添加成功' : '修改成功'
              })
              this._resetModel()
            })
          }
        })
      },
      onUnBindBtnClick(from) {
        if (this.selectFarmerList.length === 0) {
          this.$message({
            type: 'warning',
            message: '请选择养户'
          })
          return
        }
        this.$confirm('是否确认将选中养户解绑？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let farmerAccounts = []
          for (let i in this.selectFarmerList) {
            let item = this.selectFarmerList[i]
            farmerAccounts.push(item.phone)
          }
          apiUnBindFarmer(farmerAccounts, from).then(data => {
            this.$refs['farmerTable'].clearSelection()
            this._getFarmerList()
            this.$message({
              type: 'success',
              message: '解绑成功'
            })
          })
        })
      },
      onRefreshBtnClick() {
        this._getFarmerList()
      },
      // 用户列表行点击事件
      farmerRowSelect(row, event, column) {
        this.$refs['farmerTable'].toggleRowSelection(row)
      },
      // 用户多选时的回调
      farmerRowChange(selection) {
        this.selectFarmerList = selection
      },
      search() {
        this.pageIndex = 1
        this._getFarmerList()
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getFarmerList()
      },
      _clearModel() {
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      _resetModel() {
        this.model = {
          easDBKey: 'anseas',
          name: '',
          phone: '',
          password: '',
          easFarmerId: ''
        }
      },
      _getFarmerList(account = this.account, pageIndex = this.pageIndex) {
        apiGetFarmerList(account, pageIndex).then(({data}) => {
          this.farmerList = data.data
          this.farmerTotal = data.total
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .input
    width 220px
    margin-right 15px
</style>
